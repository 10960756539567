import React from "react";
import { Link } from 'react-router-dom';

import githubLogo from '../assets/images/icons/github-mark-white.svg';

export default function Header() {
  return (
    <div className="header">
      <Link className="unformatted-link header__item" id="site-title" to="/">
        <h2>cotton.org.uk</h2>
      </Link>
      <Link className="unformatted-link header__item" to="/about-me">About me</Link>
      <Link className="unformatted-link header__item" to="/downloads">Downloads</Link> 
      <Link className="unformatted-link header__item" to="https://github.com/cotton105">
        My GitHub{' '}
        <img className="header__image" src={githubLogo} alt="GitHub logo" />
      </Link>
    </div>
  );
}
