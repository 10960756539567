import TF2ClassWarsScreenshot from '../../assets/images/projects/tf2-class-wars-stat-tracker-js.png';

export default function TF2ClassWars() {
  return (
    <div className="project-content">
      <h1 className="tf2">TF2 Class Wars Stat Tracker</h1>
      <div className="project-content__img-container">
        <img src={TF2ClassWarsScreenshot} alt="TF2 Class Wars Stat Tracker" />
      </div>
      <p>
        I enjoy playing the game Team Fortress 2, especially a community game mode
        "<em>Class Wars</em>". <em>Class Wars</em> is similar to a normal game of TF2, except each
        team is restricted to playing as only one of the nine classes which are normally available.
        For example, one matchup might be Scouts versus Engineers; another might be Spies versus Snipers.
      </p>
      <p>
        Servers hosting <em>Class Wars</em> often run the <em>Roll The Dice (RTD)</em> plugin,
        which gives the player random temporary abilities during gameplay, such as invincibility,
        or increased damage output. The effect can also be negative - distracting visual effects,
        for example.
      </p>
      <p>
        As there are nine classes, this means there are <b>81</b> possible matchups, all with
        unique counters/advantages against each other. I was interested in seeing statistics for
        which class beats which, so in 2021 I started this project as a Java tool to record the win
        rate of each matchup. Later, in 2023, I migrated the project to Node.js, to become more
        familiar with JavaScript and some Node frameworks.
      </p>
      <p>
        The interface is a 9x9 grid of each of the classes, representing all of the possible
        matchups. The columns represent the <span className="tf2" style={{color: 'blue'}}>BLU </span> class,
        and the rows represent the <span className="tf2" style={{color: 'red'}}>RED </span> class.
        To record a win, you select the classes through either the buttons in the bottom-left, or
        on the grid directly, and press the "+1 BLU/RED Win" button. It's also possible to filter
        stats through a variety of options, such as map, server and game mode. In the top-left,
        there is a server banner (hosted by <a href="https://www.gametracker.com">gametracker.com</a>)
        which lists currently active players, including their score.
      </p>
      <p>
        Maps have certain quirks that can change the bias of a matchup, and some servers are
        configured differently which can also affect the bias. Another variable is the
        "<em>game mode,</em>" which is like a modifier for the <em>RTD</em> plugin. One game mode
        is "global rolls," which means that every player gets the same effect, all at the same
        time.
      </p>
    </div>
  );
}
