import tf2ClassWars from '../assets/images/projects/tf2-class-wars-stat-tracker-js.png'
import robotIcon from '../assets/images/projects/robot_icon-icons.com_60269.svg'
import passwordGenerator from '../assets/images/projects/password-generator.jpg'
import doomEngine from '../assets/images/projects/3d-game-engine.png'

export default function Home() {
  return (
    <>
      <h1>My Projects</h1>
      <div id="project-grid">
        <div>
          <a className="unformatted-link zoom" href="/projects/tf2-class-wars">
            <img src={tf2ClassWars} alt="Class-Wars app"/>
              <p></p>
          </a>
          <div className="info-box">
            <h4>TF2 Class Wars Stat Tracker</h4>
            <p>A tool for tracking the chances of which team will win in TF2 Class Wars.</p>
          </div>
        </div>
        <div>
          <a className="unformatted-link zoom" href="/projects/aio-bot">
            <img src={robotIcon} alt="Robot" />
            <p></p>
          </a>
          <div className="info-box">
            <h4>AIO Bot</h4>
            <p>A Chat/Alert Bot I worked on as a DevOps Engineer.</p>
          </div>
        </div>
        <div>
          <a className="unformatted-link zoom" href="/projects/password-generator">
            <img src={passwordGenerator} alt="Password generator" />
            <p></p>
          </a>
          <div className="info-box">
            <h4>Password Generator</h4>
            <p>A project I used to learn some C#.</p>
          </div>
        </div>
        <div>
          <a className="unformatted-link zoom" href="/projects/3d-game-engine">
            <img src={doomEngine} alt="3D Game Engine" />
            <p></p>
          </a>
          <div className="info-box">
            <h4>3D Game Engine</h4>
            <p>A first-person raycasting 3D game engine, written in Java for my university final-year project.</p>
          </div>
        </div>
      </div>
      <div id="project-details">
      </div>
    </>
  );
}
