import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './assets/styles/style.css';

import Root from './components/Root.jsx';
import ErrorPage from './pages/Error.jsx';
import Home from './pages/Home.jsx';
import AboutMe from './pages/AboutMe.jsx';

import TF2ClassWars from './pages/projects/TF2ClassWars.jsx';
import PasswordGenerator from './pages/projects/PasswordGenerator.jsx';
import AioBot from './pages/projects/AIOBot.jsx';
import DoomEngine from './pages/projects/3DGameEngine.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'about-me',
        element: <AboutMe />,
      },
      {
        path: 'projects',
        children: [
          {
            path: 'tf2-class-wars',
            element: <TF2ClassWars />,
          },
          {
            path: 'password-generator',
            element: <PasswordGenerator />,
          },
          {
            path: 'aio-bot',
            element: <AioBot />,
          },
          {
            path: '3d-game-engine',
            element: <DoomEngine />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
