export default function AboutMe() {
  return (
    <>
      <h1>About me</h1>
      <p>
        My name is Luke Cotton, and I am a Computer Science BSc graduate from the University of
        Essex. I am interested in Open Source software and its methodology.
      </p>
    </>
  );
}
