import PasswordGeneratorScreenshot from '../../assets/images/projects/password-generator.jpg';

export default function PasswordGenerator() {
  return (
    <div className="project-content">
      <h1>Password Generator</h1>
      <div className="project-content__img-container">
        <img src={PasswordGeneratorScreenshot} alt="Password Generator screenshot" />
      </div>
      <p>
        This was a small project of mine from 2022, which I used to learn some basic Windows Form GUI programming in C#. Cybersecurity is hugely important to me, so I combined these two aspects into this simple Password Generator. Another function of the project was to explore and research Cybersecurity, which I found useful as I now have a deeper understanding of what makes a good password, and how to best keep your online accounts secure.
      </p>
      <p>
        The application itself is quite primitive. There is support for customising the types of characters that are included in the generated password(s) - lower/upper case, numbers &amp; symbols, as well as the length of the password. The number of passwords to generate can also be specified. The random numbers are sourced by the C# <em>RNGCryptoServiceProvider</em> API, which produces "better" randomness than the usual <em>System.Random</em> option.
      </p>
      <p>
        I emphasize the adjustability of the passwords because there are many websites that seem to place restrictions on what your password can and can't look like. I have encountered websites that limit to a maximum of 20 characters with no symbols, which I suspect is because of storage concerns for the database (although I think this is questionable from a security standpoint).
      </p>
      <p>
        Of course, the passwords are useless without a password manager - nobody will remember a random string of 128 characters by heart. In this regard the application is lacking, as I was satisfied with my progress by the time I stopped its development. Anybody serious about their Cybersecurity should use a community-developed, self-hosted password manager, and for this I recommend <a href="https://keepassxc.org">KeePassXC</a>.
      </p>
      <p>
        Another feature is that it can be run through the command line instead of as a GUI application. Using arguments passed to the .exe, it's possible to configure all the aforementioned settings for generated passwords.
      </p>
    </div>
  );
}
